<template>
    <div v-if="!loading">
        <div class="container">
            <!-- Header -->
            <div class="row">
                <div class="col-12 py-4">
                    <div class="p-fluid container-fluid">
                        <Button class="p-button-raised p-button-rounded my-3 p-button-danger" icon="pi pi-arrow-left"
                            @click="goBack" />
                        <h3><strong>{{ this.selectedTopic.name }}</strong> Company list </h3>
                    </div>
                </div>
            </div>
        </div>

        <!-- Tables -->
        <TableCompanyListDetailed :companiesPerTopic="companiesPerTopic" />
        

    </div>
    <div v-else>
        <div class="row">
            <div class="col-12 py-4 d-flex justify-content-center">
                <ProgressSpinner />
            </div>
        </div>
    </div>

</template>
<script>
import ProgressSpinner from 'primevue/progressspinner';
import Button from 'primevue/button';

import TableCompanyListDetailed from '../components/CompanyList/TableCompanyListDetailed.vue';


export default {
    name: 'outputCompanyList',
    components: {
        ProgressSpinner,
        Button,

        TableCompanyListDetailed,
    },
    data() {
        return {
            loading: true,
            supplyChain: [
                {
                    id: 0,
                    name: 'OEM',
                },
                {
                    id: 1,
                    name: 'Tier 1'
                },
                {
                    id: 2,
                    name: 'Tier 2',
                },
                {
                    id: 3,
                    name: 'Tier 3'
                },
                {
                    name: 'not Selected'
                }
            ],

            companyTopicProfile: [
                {
                    id: 0,
                    name: "Leading Companies",
                    description: "Companies with dominant market share - extended profile in study "
                },

                {
                    id: 1,
                    name: "Important Players",
                    description: "Companies with dominant market share - extended profile in study "
                },
                {
                    id: 2,
                    name: "Other players",
                    description: "Companies that are solid contributors - no profile in study"
                },
                {
                    name: 'not Selected'
                }
            ],

        }
    },
    async mounted() {
        if (this.selectedTopic) {
            await this.getCompaniesByTopicId();
        }

    },
    computed: {
        selectedTopic() {
            return this.$store.getters.obtainSelectedTopic;
        },
        companiesPerTopic() {
            let companiesPerTopic = JSON.parse(JSON.stringify(this.$store.getters.obtainCompanyPerTopic))
            if (companiesPerTopic.length > 0) {
                companiesPerTopic.forEach(cPT => {
                    if (cPT.companyTopics.length > 0) {
                        let filteredCompanyTopic = cPT.companyTopics.filter(
                            topic => topic.topicId === this.selectedTopic.topicId
                        )[0]

                        if (filteredCompanyTopic) {
                            let supplyChain = this.supplyChain.find(SC => SC.id === filteredCompanyTopic.supplyChain)
                            let companyTopicProfile = this.companyTopicProfile.find(cTp => cTp.id === filteredCompanyTopic.companyTopicProfile)

                            cPT.topicDescription = (filteredCompanyTopic.description) ? filteredCompanyTopic.description : null
                            cPT.supplyChain = (supplyChain) ? supplyChain.name : null
                            cPT.companyTopicProfile = (companyTopicProfile) ? companyTopicProfile.name : null
                        }
                        else {
                            cPT.topicDescription = null
                            cPT.supplyChain = null
                            cPT.companyTopicProfile = null
                        }
                    }
                    else {
                        cPT.topicDescription = null
                        cPT.supplyChain = null
                        cPT.companyTopicProfile = null
                    }
                });
            }

            return companiesPerTopic;
        },
    },
    methods: {
        profileMap() {

        },

        goBack() {
            this.$router.go(-1);
        },
        async getCompaniesByTopicId() {
            try {
                this.loading = true;
                await this.$store.dispatch(
                    // 'obtainCompaniesByTopicId',
                    // this.selectedTopic.topicId
                    'obtainFilteredCompaniesByTopicIdWithTotalsByTopicId',
                    { topicId: this.selectedTopic.topicId, totals: true, byTopic: true }
                );
                this.loading = false;
            } catch (error) {
                this.$store.dispatch(
                    'obtainCompaniesByTopicIdNotFound',
                );
                this.loading = false;
            }

        }
    },
    watch: {
        async selectedTopic(newValue) {
            if (newValue) {
                await this.getCompaniesByTopicId();
            }
        },

    }



}
</script>
<style lang="">

</style>