<template>
    <div v-if="!loading">
        <div class="container" style="margin-bottom: 3rem;">
            <h3 style="margin-bottom: 1rem;"> Table 1: List of companies detailed</h3>
            <table
                style="
                    width: 100%;
                    height: 100%;
                "
            >
                <!-- header Table -->
                <tr>
                    <th
                        v-for="(header, index ) in this.header"
                        :key="index"
                        class="companyListOutputTh"
                    >
                        {{ header}}
                    </th>
                </tr>
                <!-- data -->
                <tr

                    v-for="(row, rowIndex) in companyData"
                    :key="rowIndex"
                    class="CompanyListOutputTrTextAlign"
                >   
                    <td
                        v-for="(cell, cellIndex) in row"
                        :key="cellIndex"
                        class="companyListOutputTh"
                    >
                        {{cell}}
                    </td>
                </tr>
            </table>

            <div
                style="position: relative;"
            >
                <CopyTableCompanyData
                    :header="this.header"
                    :companyData="companyData"
                />
            </div>

        </div>

        <div class="container" style="margin-bottom: 3rem;">
            <Divider></Divider>
        </div>
        
        

        <div class="container" style="margin-bottom: 3rem;">
            <h3 style="margin-bottom: 1rem;">Table 2: List of companies short</h3>
            <table
                style="
                    width: 100%;
                    height: 100%;
                "
            >
                <!-- header Table -->
                <tr>
                    <th
                        v-for="(header, index ) in this.headerShort"
                        :key="index"
                        class="companyListOutputTh"
                    >
                        {{ header}}
                    </th>
                </tr>

                <tr
                    v-for="(row, rowIndex) in companyDataShort"
                    :key="rowIndex"
                    class="CompanyListOutputTrTextAlign"
                >  
                    <td
                        class="companyListOutputTh"
                    >
                        {{ row.name  }}
                    </td> 
                </tr>

            </table>

            <div
                style="position: relative;"
            >
                <CopyTableCompanyDataShort
                    :header="this.headerShort"
                    :companyData="companyDataShort"
                />
            </div>

        </div>

    </div>
</template>
<script>
import Divider from 'primevue/divider';

import CopyTableCompanyData from './CopyTableCompanyData.vue';
import CopyTableCompanyDataShort from './CopyTableCompanyDataShort.vue';


export default {
    name: 'TableCompanyListDetailed',
    props: ['companiesPerTopic'],
    components: {
        Divider,
        CopyTableCompanyData,
        CopyTableCompanyDataShort
    },
    mounted() {
        this.loading  = true
        this.convertHeaderTable();
        this.convertDataTable();
        this.convertDataTableShort();
        this.loading  = false 
    },
    data() {
        return {
            loading : true,
            companyPerTopicToSort : [],
            header: [],
            companyData:[],

            headerShort: [],
            companyDataShort: [],
        };
    },
    methods:{
        convertHeaderTable(){
            // NAME, PROFILETYPE , SUPPLYCHAIN, PROGRAMS, DOCUMENTS,
            this.header= ["Name", "ProfileType", "SupplyChain", "Programs", "Documents"]
            this.headerShort= ["Name"]
        },

        convertDataTable(){
            let companies = JSON.parse( JSON.stringify(this.companiesPerTopic));

            const topicOrder = [
                "Leading Companies",
                "Important players",
                "Other players",
                "not Selected"
            ];

            let orderedCompanies = [];  

            topicOrder.forEach(topic => {
                // fillter by TopicOrder 
                const filtered = companies
                    .filter(c => {
                        const companyTopic = (c.companyTopicProfile)? c.companyTopicProfile.toLowerCase():'not Selected'.toLocaleLowerCase()
                        return companyTopic === topic.toLowerCase();
                    })
                    .sort((a, b) => (a.name || "").localeCompare(b.name || "")); // sort By name

                orderedCompanies.push(...filtered);
            });

            orderedCompanies.forEach(cPT => {
                const company = {
                    name: cPT.name,
                    companyTopicProfile: cPT.companyTopicProfile,
                    supplyChain : cPT.supplyChain,
                    totalPrograms : cPT.totalPrograms,
                    totalDocuments: cPT.totalDocuments
                }
                this.companyData.push(company)
            });

        },

        convertDataTableShort(){
            let companies = JSON.parse( JSON.stringify(this.companiesPerTopic));

            companies.sort((x, y) => {
                if (x.name > y.name) return 1;
                if (x.name < y.name) return -1;
                return 0;
            });

            this.companyDataShort = companies
            
        },
    }

}

</script>
<style>
.CompanyListOutputTrTextAlign>*:not(:first-child) {
    text-align: right;
}

.companyListOutputTh{
    border: solid gray 1px;

    /* padding: 0 1.5% 0 1.5%; */
    padding: 0 0.3% 0 0.3%;
}



</style>