<template>
    <div
        style="position: relative;"
    >
        <Button @click="copyToClipBoard()">
            Copy Table
        </Button>

        <table
            style="
                width: 100rem;
                height: 100%;
                position: absolute;
                top: -9999px;
                left: -9999px;
            "
            :id="'CopyTableCompanyDataShortId'"
        >
            <!-- header Table -->

            <tr>
                <th
                    v-for="(header, index ) in this.header"
                    :key="index"
                    class="copyTableCompanyListTh "
                >
                    <div >
                        {{ header}}
                    </div>
                </th>
            </tr>
            <!-- data -->
            <tr

                v-for="(row, rowIndex) in companyData"
                :key="rowIndex"
            >   
                <td
                    class="copyTableCompanyListTd"
                >
                    {{ row.name  }}
                </td> 
            </tr>
        </table>

    </div>
</template>

<script>
import Button from 'primevue/button';

export default {
    name: 'CopyTableCompanyData',
    props: ['header','companyData'],
    components:{
        Button,

    },
    mounted() {
        
    },
    data() {
        return {
            
        };
    },
    methods:{
        copyToClipBoard(){
            var range = document.createRange();
            let containerNode = document.getElementById('CopyTableCompanyDataShortId');
            
            containerNode.style.position = 'static'; // move content to the screen
            
            range.selectNode(containerNode);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);
            document.execCommand("copy")
            window.getSelection().removeAllRanges();

            containerNode.style.position = 'absolute'; // hide content off the screen
            containerNode.style.top= '-9999px';
            containerNode.style.left= '-9999px';
            alert("CompanyList copied");
        },
        
    }
}


</script>

<style scoped>
.copyTableCompanyListTh{
    background-color: #3f5f8f;
    border-collapse: collapse;
    border: 1px solid #cad8ef;
    font-family: Arial;
    font-size: 12px;
    text-align: center;
    color: #FFFFFF;
    vertical-align: top;
}

.copyTableCompanyListTd{
    border-collapse: collapse;
    border: 1px solid #cad8ef;
    padding: 2px;
    font-family: Arial;
    font-size: 11px;
}


.headerTableIndexothers0{
    text-align: right;

}



</style>